import { CLEAR_SUPPORTED_OFF_EXCHANGE_STATES_ACTION } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_SUPPORTED_OFF_EXCHANGE_STATES_ACTION } from 'actions/whitelistedCarriers/getSupportedOffExchangeStates';

const initialState: string[] = [];

export const supportedOffExchangeStates = (state = initialState, action: IAction<string[]>) => {
    if (action.type === GET_SUPPORTED_OFF_EXCHANGE_STATES_ACTION.success) {
        return action.data;
    } else if (action.type === CLEAR_SUPPORTED_OFF_EXCHANGE_STATES_ACTION) {
        return initialState;
    }
    return state;
};
