import React from 'react';

export type ITeamManagementContext = {
    isTeamManagementPage?: boolean;
    selectedMembers?: string[];
    setIsTeamManagementPage?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setSelectedMembers?: React.Dispatch<React.SetStateAction<string[] | undefined>>;
};
const TeamManagementContext = React.createContext<ITeamManagementContext>({});

export default TeamManagementContext;
