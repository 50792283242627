import { CLEAR_WHITELISTED_CARRIERS_ACTION } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { DELETE_WHITELISTED_CARRIER } from 'actions/whitelistedCarriers/deleteWhitelistedCarrier';
import { GET_WHITELISTED_CARRIERS_ACTION } from 'actions/whitelistedCarriers/getWhitelistedCarriers';
import { TOGGLE_WHITELISTED_IDEON_CARRIER_ARCHIVED_ACTION } from 'actions/whitelistedCarriers/toggleWhitelistedIdeonCarrierArchived';
import { IWhitelistedIdeonCarrierDto } from 'api/generated/models';

const initialState: IWhitelistedIdeonCarrierDto[] = [];

export const whitelistedIdeonCarriers = (
    state = initialState,
    action: IAction<IWhitelistedIdeonCarrierDto | IWhitelistedIdeonCarrierDto[]>
) => {
    switch (action.type) {
        case GET_WHITELISTED_CARRIERS_ACTION.success:
            return action.data as IWhitelistedIdeonCarrierDto[];
        case CLEAR_WHITELISTED_CARRIERS_ACTION:
            return initialState;
        case DELETE_WHITELISTED_CARRIER.success:
            return [
                ...state.filter((x) => x.id !== (action.data as IWhitelistedIdeonCarrierDto).id),
            ];
        case TOGGLE_WHITELISTED_IDEON_CARRIER_ARCHIVED_ACTION.success: {
            const data = action.data as IWhitelistedIdeonCarrierDto;
            return [...state.filter((x) => x.id !== data.id), data];
        }
        default:
            return state;
    }
};
