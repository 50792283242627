import { CLEAR_STATE } from 'actions/clear';
import { INVALIDATE_TOKEN_ACTION } from 'actions/token/invalidateToken';
import { addressInputState } from 'components/addressInput/addressInputState';
import { newVersionState } from 'components/newVersion/newVersionState';
import { notesState } from 'components/note/notesState';
import { passwordInputsState } from 'components/passwordInputs/passwordInputsState';
import { planModalState } from 'components/planModal/planModalState';
import { underConstructionModalState } from 'components/underConstructionModal/underConstructionModalState';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { benefitsState } from 'pages/benefits/benefitsState';
import { ichraFlowState } from 'pages/dashboard/ichraTaskFlow/ichraFlowState';
import { benefitSelectionState } from 'pages/dashboard/medicalBenefitSelection/benefitSelectionState';
import { customActiveWageUpModalState } from 'pages/pathwayBlueprint/customActiveWageUpModalState';
import { customPlanCostModalState } from 'pages/pathwayBlueprint/customPlanCostModalState';
import { pathwayCustomProjectedModalState } from 'pages/pathwayBlueprint/pathwayCustomProjectedModalState';
import { pathwayModalState } from 'pages/pathwayBlueprint/pathwayModalState';
import { pathwayBlueprintModalState } from 'pages/pathwayBlueprints/pathwayBlueprintModalState';
import { payrollReportState } from 'pages/payrollReports/payrollReportState';
import { peopleState } from 'pages/people/peopleState';
import { profileState } from 'pages/profile/profileState';
import { reimbursementReportState } from 'pages/reimbursementReports/reimbursementReportState';
import { shoppingState } from 'pages/shop/shoppingState';
import { surveyState } from 'pages/survey/surveyState';
import { teamState } from 'pages/teams/teamState';
import { teamsState } from 'pages/teams/teamsState';
import { advisementTeams } from 'reducers/advisementTeams';
import { agePremiumRatios } from 'reducers/agePremiumRatios';
import { ancillaryBenefits } from 'reducers/ancillaryBenefits';
import { apiActivity } from 'reducers/apiActivity';
import { apiErrors } from 'reducers/apiErrors';
import { appliedStrategy } from 'reducers/appliedStrategy';
import { blueprintMigrations } from 'reducers/blueprintMigrations';
import { calendlyLink } from 'reducers/calendlyLink';
import { counties } from 'reducers/counties';
import { current } from 'reducers/current';
import { customQuestionAnswers } from 'reducers/customQuestionAnswers';
import { customQuestions } from 'reducers/customQuestions';
import { dataForEnrollmentCompletion } from 'reducers/dataForEnrollmentCompletion';
import { defaultMemberDataYear } from 'reducers/defaultMemberDataYear';
import { drugs } from 'reducers/drugs';
import { enrollmentInfo } from 'reducers/enrollmentInfo';
import { enrollmentVerifications } from 'reducers/enrollmentVerifications';
import { expenseTypes } from 'reducers/expenseTypes';
import { favoriteTeams } from 'reducers/favoriteTeams';
import { finch } from 'reducers/finch';
import { householdDrugs } from 'reducers/householdDrugs';
import { householdEligibility } from 'reducers/householdEligibility';
import { householdMembers } from 'reducers/householdMembers';
import { householdProviders } from 'reducers/householdProviders';
import { householdShoppingCoverage } from 'reducers/householdShoppingCoverage';
import { hrsBenefitsEligibilityClassCodes } from 'reducers/hrsBenefitsEligibilityClassCodes';
import { ichraOffer } from 'reducers/ichraOffer';
import { ideonCarriers } from 'reducers/ideonCarriers';
import { ideonProviderPlans } from 'reducers/ideonProviderPlans';
import { ideonProviders } from 'reducers/ideonProviders';
import { ideonProvidersInPlanNetworks } from 'reducers/ideonProvidersInPlanNetworks';
import { importedAgeBandedTable } from 'reducers/importedAgeBandedTable';
import { launchDetails } from 'reducers/launchDetails';
import { login } from 'reducers/login';
import { manageMembersState } from 'reducers/manageMembersState';
import { marketplaceConfig } from 'reducers/marketplaceConfig';
import { marketplacePlans } from 'reducers/marketplacePlans';
import { mediSharePlans } from 'reducers/mediSharePlans';
import { medicaid } from 'reducers/medicaid';
import { memberBenefits } from 'reducers/memberBenefits';
import { memberReimbursementUtilizations } from 'reducers/memberReimbursementUtilizations';
import { mostRecentReimbursementReport } from 'reducers/mostRecentReimbursementReport';
import { options } from 'reducers/options';
import { pagedTeamProfiles } from 'reducers/pagedTeamProfiles';
import { pagedUserProfiles } from 'reducers/pagedUserProfiles';
import { pagedUserTermDetails } from 'reducers/pagedUserTermDetails';
import { pathway } from 'reducers/pathway';
import { pathwayBlueprint } from 'reducers/pathwayBlueprint';
import { pathwayBlueprints } from 'reducers/pathwayBlueprints';
import { pathwayDataForUser } from 'reducers/pathwayDataForUser';
import { pathwayUserProfileInfo } from 'reducers/pathwayUserProfileInfo';
import { paymentsInfo } from 'reducers/paymentsInfo';
import { payrollReportYearMonth } from 'reducers/payrollReportYearMonth';
import { payrollReports } from 'reducers/payrollReports';
import { permissions } from 'reducers/permissions';
import { providers } from 'reducers/providers';
import { qualitativeAnswers } from 'reducers/qualitativeAnswers';
import { recommendedPlans } from 'reducers/recommendedPlans';
import { reimbursementReport } from 'reducers/reimbursementReport';
import { reimbursementReportYears } from 'reducers/reimbursementReportYears';
import { reimbursementReports } from 'reducers/reimbursementReports';
import { reimbursementReportsYearly } from 'reducers/reimbursementReportsYearly';
import { resources } from 'reducers/resources';
import { rhFiles } from 'reducers/rhFiles';
import { rhPasswordOptions } from 'reducers/rhPasswordOptions';
import { role } from 'reducers/role';
import { roles } from 'reducers/roles';
import { search } from 'reducers/search';
import { selectedPlans } from 'reducers/selectedPlans';
import { selectedPlansForRenewal } from 'reducers/selectedPlansForRenewal';
import { selectedPlansForReview } from 'reducers/selectedPlansForReview';
import { shoppingPathwayMarketplacePlan } from 'reducers/shoppingPathwayMarketplacePlan';
import { shoppingPathwayMediSharePrograms } from 'reducers/shoppingPathwayMediSharePrograms';
import { singleFilerStandardDeduction } from 'reducers/singleFilerStandardDeduction';
import { socialSecurityNumbers } from 'reducers/socialSecurityNumbers';
import { states } from 'reducers/states';
import { stats } from 'reducers/stats';
import { submittedExpense } from 'reducers/submittedExpense';
import { assignableReimbursementReports } from 'reducers/submittedExpenseAssignableReimbursementReports';
import { submittedExpenseModalUtilizations } from 'reducers/submittedExpenseModalUtilizations';
import { submittedExpenses } from 'reducers/submittedExpenses';
import { supportedOffExchangeStates } from 'reducers/supportedOffExchangeStates';
import { survey } from 'reducers/survey';
import { surveyIdeonProviders } from 'reducers/surveyIdeonProviders';
import { taxData } from 'reducers/taxData';
import { teamAdvisors } from 'reducers/teamAdvisors';
import { teamBenefit } from 'reducers/teamBenefit';
import { teamBenefitTypeCarriers } from 'reducers/teamBenefitTypeCarriers';
import { teamBenefitTypes } from 'reducers/teamBenefitTypes';
import { teamBenefits } from 'reducers/teamBenefits';
import { teamFinch } from 'reducers/teamFinch';
import { teamNotes } from 'reducers/teamNotes';
import { teamOperationsInfo } from 'reducers/teamOperationsInfo';
import { teamPlans } from 'reducers/teamPlans';
import { teamProfile } from 'reducers/teamProfile';
import { teamProfiles } from 'reducers/teamProfiles';
import { teamRelationships } from 'reducers/teamRelationships';
import { teamRepresentativeOptions } from 'reducers/teamRepresentativeOptions';
import { teamRoles } from 'reducers/teamRoles';
import { teamTasks } from 'reducers/teamTasks';
import { teamUserRoles } from 'reducers/teamUserRoles';
import { user } from 'reducers/user';
import { userBudget } from 'reducers/userBudget';
import { userBudgets } from 'reducers/userBudgets';
import { userFlows } from 'reducers/userFlows';
import { userInfoToSubmitExpense } from 'reducers/userInfoToSubmitExpense';
import { userNotes } from 'reducers/userNotes';
import { userProfile } from 'reducers/userProfile';
import { userProfiles } from 'reducers/userProfiles';
import { userProfilesMetadata } from 'reducers/userProfilesMetadata';
import { userTasks } from 'reducers/userTasks';
import { verifiedInfoComparison } from 'reducers/verifiedInfoComparison';
import { videos } from 'reducers/videos';
import { whitelistedIdeonCarriers } from 'reducers/whitelistedIdeonCarriers';
import { combineReducers } from 'redux';
import { appRouterReducer } from 'routers/appRouterReducer';

const appReducer = (history: History) => {
    const router = connectRouter(history);
    return combineReducers({
        addressInputState,
        advisementTeams,
        agePremiumRatios,
        ancillaryBenefits,
        apiActivity,
        apiErrors,
        appliedStrategy,
        appRouterReducer,
        assignableReimbursementReports,
        benefitSelectionState,
        benefitsState,
        blueprintMigrations,
        calendlyLink,
        counties,
        current,
        customActiveWageUpModalState,
        customPlanCostModalState,
        customQuestionAnswers,
        customQuestions,
        dataForEnrollmentCompletion,
        defaultMemberDataYear,
        drugs,
        enrollmentInfo,
        enrollmentVerifications,
        expenseTypes,
        favoriteTeams,
        finch,
        householdDrugs,
        householdEligibility,
        householdMembers,
        householdProviders,
        householdShoppingCoverage,
        hrsBenefitsEligibilityClassCodes,
        ichraFlowState,
        ichraOffer,
        ideonCarriers,
        ideonProviderPlans,
        ideonProviders,
        ideonProvidersInPlanNetworks,
        importedAgeBandedTable,
        launchDetails,
        login,
        manageMembersState,
        marketplaceConfig,
        marketplacePlans,
        medicaid,
        mediSharePlans,
        memberBenefits,
        memberReimbursementUtilizations,
        mostRecentReimbursementReport,
        newVersionState,
        notesState,
        options,
        pagedTeamProfiles,
        pagedUserProfiles,
        pagedUserTermDetails,
        passwordInputsState,
        pathway,
        pathwayBlueprint,
        pathwayBlueprintModalState,
        pathwayBlueprints,
        pathwayCustomProjectedModalState,
        pathwayDataForUser,
        pathwayModalState,
        pathwayUserProfileInfo,
        paymentsInfo,
        payrollReports,
        payrollReportState,
        payrollReportYearMonth,
        peopleState,
        permissions,
        planModalState,
        profileState,
        providers,
        qualitativeAnswers,
        recommendedPlans,
        reimbursementReport,
        reimbursementReports,
        reimbursementReportState,
        reimbursementReportsYearly,
        reimbursementReportYears,
        resources,
        rhFiles,
        rhPasswordOptions,
        role,
        roles,
        router,
        search,
        selectedPlans,
        selectedPlansForRenewal,
        selectedPlansForReview,
        shoppingPathwayMarketplacePlan,
        shoppingPathwayMediSharePrograms,
        shoppingState,
        singleFilerStandardDeduction,
        socialSecurityNumbers,
        states,
        stats,
        submittedExpense,
        submittedExpenseModalUtilizations,
        submittedExpenses,
        supportedOffExchangeStates,
        survey,
        surveyIdeonProviders,
        surveyState,
        taxData,
        teamAdvisors,
        teamBenefit,
        teamBenefits,
        teamBenefitTypeCarriers,
        teamBenefitTypes,
        teamFinch,
        teamNotes,
        teamOperationsInfo,
        teamPlans,
        teamProfile,
        teamProfiles,
        teamRelationships,
        teamRepresentativeOptions,
        teamRoles,
        teamsState,
        teamState,
        teamTasks,
        teamUserRoles,
        underConstructionModalState,
        user,
        userBudget,
        userBudgets,
        userFlows,
        userInfoToSubmitExpense,
        userNotes,
        userProfile,
        userProfiles,
        userProfilesMetadata,
        userTasks,
        verifiedInfoComparison,
        videos,
        whitelistedIdeonCarriers,
    });
};
export type AppStore = ReturnType<ReturnType<typeof appReducer>>;

export const createRootReducer = (history: History) => (
    state: AppStore | undefined,
    action: { type: string }
) => {
    if (action.type === INVALIDATE_TOKEN_ACTION.success || action.type === CLEAR_STATE) {
        state = undefined;
    }
    return appReducer(history)(state, action);
};
