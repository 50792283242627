import { CLEAR_IDEON_CARRIERS_ACTION } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { IIdeonCarrierDto } from 'api/generated/models';
import { SEARCH_IDEON_CARRIERS_ACTION } from 'pages/whitelistedCarrierManagement/searchIdeonCarriers';

const initialState: IIdeonCarrierDto[] = [];

export const ideonCarriers = (state = initialState, action: IAction<IIdeonCarrierDto[]>) => {
    if (action.type === SEARCH_IDEON_CARRIERS_ACTION.success) {
        return action.data;
    } else if (action.type === CLEAR_IDEON_CARRIERS_ACTION) {
        return initialState;
    }
    return state;
};
